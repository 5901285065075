import { ReadBot } from '@/infrastructure/http-layer/apiServices';
import { createSlice } from '@reduxjs/toolkit';

interface optionsStore {
	activeBot: ReadBot | null
}
const initialState: optionsStore = {
	activeBot: null,
};
const optionsSlice = createSlice({
	name: 'options',
	initialState: initialState,
	reducers: {
		setActiveBot: (state, action) => {
			state.activeBot = action.payload;
		},
		clearActiveBot: (state) => {
			state.activeBot = null;
		}
	},
});
export const optionsReducer = optionsSlice.reducer;
export const { setActiveBot, clearActiveBot } = optionsSlice.actions;
