import AuthModal from '@/components/common/auth-modal/auth-modal';
import { usePasswordReset } from '@/framework/react-query/auth-hooks';
import { useState, useEffect } from 'react';
import styles from './password-recovery.module.scss';
import Input from '@/components/common/input/input';
import Button from '@/components/common/button/button';
import { LOGIN_PATH, SIGNIN_PATH } from '@/core/config/routes';
import { Link } from 'react-router-dom';

const PasswordRecovery = () => {
	const [email, setEmail] = useState('');
	const [codeSent, setCodeSent] = useState(false);
	const { mutate: resetPassword, isSuccess } = usePasswordReset();

	const submit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (email) {
			resetPassword({ email });
		}
	};

	useEffect(() => {
		if (isSuccess) {
			setCodeSent(true);
		}
	}, [isSuccess]);

	return (
		<div className={'main_wrapper'}>
			{!codeSent ? (
				<AuthModal>
					<p className={styles['password-recovery-text']}>
						Введите ваш e-mail, к которому привязана учетная запись, чтобы сбросить пароль
					</p>
					<form onSubmit={submit}>
						<Input
							className={styles['password-recovery-input']}
							placeholder="Электронная почта"
							onChange={(e) => {
								setEmail(e.currentTarget.value);
							}}
							value={email}
						/>
						<Button type={'submit'}>Сбросить пароль</Button>
					</form>
					<div className={styles['password-recovery_links_container']}>
						<Link className={styles['password-recovery_link']} to={LOGIN_PATH}>
							Войти
						</Link>
						<Link className={styles['password-recovery_link']} to={SIGNIN_PATH}>
							Зарегистрироваться
						</Link>
					</div>
				</AuthModal>
			) : (
				<AuthModal>
					<p className={styles.confirm_text}>
						Письмо для подтверждения отправлено на адрес <strong>{email}</strong>.
					</p>
					<p className={styles.confirm_text}>
						Пожалуйста, перейдите по ссылке, <br />
						указанной в этом письме. Если вы не видите письма, проверьте папку со спамом. Спасибо.
					</p>
					<Link className={styles.confirm_link} to={LOGIN_PATH}>
						Войти
					</Link>
				</AuthModal>
			)}
		</div>
	);
};

export default PasswordRecovery;
