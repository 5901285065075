import { useAppDispatch } from '@/infrastructure/store-layer/hooks';
import { clearActiveBot } from '@/infrastructure/store-layer/slices/options-slice';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PASSWORD_RECOVERY, BOTS_PATH, SIGNIN_PATH } from '../../../core/config/routes';
import { getCookie } from '../../../core/utils/cookieUtils';
import { useLogin } from '../../../framework/react-query/auth-hooks';
import AuthModal from '../../common/auth-modal/auth-modal';
import Button from '../../common/button/button';
import Input from '../../common/input/input';
import styles from './login.module.scss';

const Login = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { mutate, isSuccess, error } = useLogin();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [passwordError, setPasswordError] = useState('');

	const refresh = getCookie('notishop_refresh');
	const access = localStorage.getItem('notishop_access');

	useEffect(() => {
		dispatch(clearActiveBot());
	}, []);

	useEffect(() => {
		if (refresh) {
			navigate(BOTS_PATH);
		}
	}, [access, refresh]);

	useEffect(() => {
		if (error && error.response) {
			if (error.response.status === 401 || error.response.status === 400) {
				setPasswordError('Неверный пароль');
			}
		}
	}, [error]);

	const handleAuth = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		localStorage.removeItem('notishop_access');
		mutate({ email, password });
	};

	useEffect(() => {
		if (isSuccess) {
			navigate(BOTS_PATH);
		}
	}, [isSuccess]);

	return (
		<div className="main_wrapper">
			<AuthModal>
				<form onSubmit={(e) => handleAuth(e)}>
					<p className={styles.login_subtitle}>
						Начните с бесплатного 14-ти дневного пробного периода
					</p>
					<div className={styles.login_form}>
						<Input
							placeholder="Электронная почта"
							onChange={(e) => {
								setPasswordError('');
								setEmail(e.currentTarget.value);
							}}
							type="email"
							value={email}
							required
						/>
						<Input
							type="password"
							placeholder="Пароль"
							onChange={(e) => {
								setPasswordError('');
								setPassword(e.currentTarget.value);
							}}
							value={password}
							error={passwordError}
							required
						/>
					</div>
					<Button className={styles.login_button} type={'submit'}>
						Войти
					</Button>
				</form>
				<div className={styles.login_links_container}>
					<Link className={styles.login_link} to={SIGNIN_PATH}>
						Зарегистрироваться
					</Link>
					<Link className={styles.login_link} to={PASSWORD_RECOVERY}>
						Забыли пароль
					</Link>
				</div>
			</AuthModal>
		</div>
	);
};

export default Login;
