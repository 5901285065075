export const LOGIN_PATH = '/login';
export const SIGNIN_PATH = '/signin';
export const MAIN_PATH = '/login';
export const EMAIL_VERIFY_PATH = '/email';
export const PASSWORD_RESET = '/password_reset';
export const PASSWORD_RECOVERY = '/password_recovery';

export const CATEGORIES_PATH = '/categories';
export const ADD_CATEGORY_PATH = `${CATEGORIES_PATH}/add`;
export const EDIT_CATEGORY_PATH = `${CATEGORIES_PATH}/edit/:categoryID`;

export const PRODUCTS_PATH = `${CATEGORIES_PATH}/:categoryId/products`;
export const ADD_PRODUCT_PATH = `${CATEGORIES_PATH}/:categoryID/products/add`;
export const PRODUCT_DETAIL_PATH = `${CATEGORIES_PATH}/:categoryID/products/:productId`;

export const PROMOSETS_PATH = '/promosets';
export const EDIT_PROMOSETS_PATH = '/promosets/:promosetId';

export const MAILING_PATH = '/mailing';

export const BOTS_PATH = '/bots';

export const ORDERS_PATH = '/orders';
export const ADD_ORDER_PATH = ORDERS_PATH + '/add';

export const SETTINGS_PATH = '/settings';
export const BOT_SETTINGS_PATH = SETTINGS_PATH + '/bot';
export const COMPANY_SETTINGS_PATH = SETTINGS_PATH + '/company';
export const DELIVERY_SETTINGS_PATH = SETTINGS_PATH + '/delivery';
export const STAFF_SETTINGS_PATH = SETTINGS_PATH + '/staff';
export const WEBAPP_SETTINGS_PATH = SETTINGS_PATH + '/webapp';

export const REPORTS_PATH = '/reports';

export const SUBSCRIBERS_PATH = '/subscribers';

export const RATES_PATH = '/rates';
export const ACCOUNT_PATH = '/account';

export const LINK_EDIT_CATEGORY = (id: string) => `${CATEGORIES_PATH}/edit/${id}`;
export const LINK_PRODUCTS_PATH = (categoryId: number) =>
	CATEGORIES_PATH + `/${categoryId}/products`;
export const LINK_PROMOSET_PATH = (id: string) => `${PROMOSETS_PATH}/${id}`;
