import AuthModal from '@/components/common/auth-modal/auth-modal';
import { LOGIN_PATH } from '@/core/config/routes';
import { usePasswordRecovery } from '@/framework/react-query/auth-hooks';
import Input from '@/components/common/input/input';
import Button from '@/components/common/button/button';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import styles from './password-reset.module.scss';

const PasswordReset = () => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [token, setToken] = useState<string>('');
	const [passwordError, setPasswordError] = useState(false);
	const [uid, setUid] = useState('');
	const { mutate, isSuccess, isError } = usePasswordRecovery();

	useEffect(() => {
		const tokenFromParams = searchParams.get('token');
		const uidFromParams = searchParams.get('uidb');
		if (tokenFromParams && uidFromParams) {
			setToken(tokenFromParams);
			setUid(uidFromParams);
		} else {
			navigate(LOGIN_PATH);
		}
	}, [searchParams]);

	const changePassword = () => {
		if (password === confirmPassword) {
			mutate({ password, token, uidb64: uid });
		} else {
			setPasswordError(true);
		}
	};

	useEffect(() => {
		if (isError) {
			alert(
				'Пароль должен соответствовать правилам:\nДлина пароля от 8 символов\nИспользовать латинские буквы и цифры'
			);
		}
	}, [isError]);

	return (
		<div className={'main_wrapper'}>
			{isSuccess && (
				<AuthModal>
					<p>Ваш пароль успешно изменен!</p>
					<Link to={LOGIN_PATH} className={styles.login_link}>
						Войти в аккаунт
					</Link>
				</AuthModal>
			)}
			{!isSuccess && (
				<AuthModal>
					<p className={styles['password-text']}>
						Для сброса пароля придумайте новый. Для вашей безопасности, не используйте прочие
						комбинации 123456...
					</p>
					<Input
						placeholder="Пароль"
						value={password}
						onChange={(e) => {
							setPasswordError(false);
							setPassword(e.currentTarget.value);
						}}
						type="password"
					/>
					<Input
						className={styles['password-confirm-input']}
						placeholder="Повторите пароль"
						value={confirmPassword}
						onChange={(e) => {
							setPasswordError(false);
							setConfirmPassword(e.currentTarget.value);
						}}
						type="password"
						error={passwordError ? 'Пароли не совпадают' : ''}
					/>
					<Button
						type="button"
						className={styles['password-button']}
						onClick={() => changePassword()}
					>
						Отправить
					</Button>
				</AuthModal>
			)}
		</div>
	);
};

export default PasswordReset;
