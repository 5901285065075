import { IButton } from './button.types';
import styles from './button.module.scss';
import classNames from 'classnames';

const Button = ({ onClick, disabled = false, children, className, type, ...props }: IButton) => {
	const buttonClassnames = classNames(styles.button, className);
	return (
		<button type={type} className={buttonClassnames} onClick={(e) => onClick && onClick(e)} disabled={disabled} {...props}>
			{children}
		</button>
	);
};

export default Button;
