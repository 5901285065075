import { LOGIN_PATH } from '@/core/config/routes';
import { getCookie, removeCookie } from '../../core/utils/cookieUtils';
import { Api } from './apiServices';
import axios from 'axios';

export const apiService = new Api({
	baseURL: '/api',
	withCredentials: true,
	headers: {
		'Content-Type': 'application/json; charset=UTF-8',
		'X-Requested-With': 'XMLHttpRequest',
	},
});

function refresh(token: string | undefined) {
	return axios.post('/api/accounts/token/refresh', { refresh: token });
}

apiService.instance.interceptors.response.use(
	(response) => {
		return response;
	},
	async (error) => {
		const originalRequest = error.config;
		const errStatus = error.response.status;
		if ((errStatus === 401 || errStatus === 403) && !originalRequest._retry && !error.response.request.responseURL.includes(LOGIN_PATH)) {
			try {
				const refresh_token = getCookie('notishop_refresh');
				originalRequest._retry = true;
				await refresh(refresh_token).then((result) =>
					localStorage.setItem('notishop_access', result.data.access)
				).catch(() => {
					localStorage.removeItem('notishop_access');
					removeCookie('notishop_refresh');
					window.location.href = '/';
				});
				return apiService.instance(originalRequest);
			} catch (err) {
				return apiService.instance(originalRequest);
			}
		} else if (errStatus === 403 && originalRequest._retry) {
			localStorage.removeItem('notishop_access');
			removeCookie('notishop_refresh');
		}
		throw error;
	}
);

apiService.instance.interceptors.request.use((config) => {
	try {
		const accessToken = localStorage.getItem('notishop_access');
		if (config.headers && accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
		return config;
	} catch (err) {
		return Promise.reject(err);
	}
});
