export const EMAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const emailValidation = (email: string) => {
	if (!email) {
		return false;
	}
	if (EMAIL_REGEX.test(email)) {
		return true;
	} else {
		return false;
	}
};
