import { useMutation, useQuery } from 'react-query';
import { setCookie } from '../../core/utils/cookieUtils';
import { apiService } from '../../infrastructure/http-layer/api';
import { Login, Register, ResetPasswordRequest, SetNewPassword } from '../../infrastructure/http-layer/apiServices';

export const useSignin = () => {
	return useMutation((data: Register) => apiService.accounts.register(data), {
		onError(error: any) {
			return error;
		},
	});
};

export const useLogin = () => {
	return useMutation((data: Login) => apiService.accounts.login(data), {
		onError(error: any) {
			return error;
		},
		onSuccess: (data) => {
			if (data.data.tokens) {
				setCookie('notishop_refresh', data.data.tokens.refresh, 30);
				localStorage.setItem('notishop_access', data.data.tokens.access);
			}
		},
	});
};

export const useEmailConfirm = (token: string) => {
	const {
		isLoading,
		data: verifyData,
		error,
		refetch,
		isFetching,
	} = useQuery({
		queryKey: ['user'],
		queryFn: () => apiService.accounts.getEmailVerify({ token }),
		select: (data) => {
			const emailVerifyData = data.data;
			return emailVerifyData;
		},
		onError(error: any) {
			return error;
		},
		enabled: !!token,
	});

	return { isLoading, verifyData, error, refetch, isFetching };
};

export const usePasswordReset = () => {
	return useMutation((data: ResetPasswordRequest) => apiService.accounts.requestResetEmail(data), {
		onError(error: any) {
			return error;
		},
	});
};

export const usePasswordRecovery = () => {
	return useMutation((data: SetNewPassword) => apiService.accounts.passwordResetComplete(data), {
		onError(error: any) {
			return error;
		},
	});
};
