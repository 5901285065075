import { lazy, Suspense } from 'react';
import './App.scss';
import { Routes, Route } from 'react-router-dom';
import ProtectedAuthRoute from './framework/router/protected-auth-route';
import { EMAIL_VERIFY_PATH, LOGIN_PATH, PASSWORD_RECOVERY, PASSWORD_RESET, SIGNIN_PATH } from './core/config/routes';
import Login from './components/layouts/login/login';
import SignIn from './components/layouts/sign-in/sign-in';
import PasswordReset from './components/layouts/password-reset/password-reset';
import PasswordRecovery from './components/layouts/password-recovery/password-recovery';
const MainLayout = lazy(() => import('./components/layouts/main-layout/main-layout'));
const EmailVerify = lazy(() => import('./components/layouts/email-verify/email-verify'));

function App() {
	return (
		<div className="App">
			<Suspense>
				<Routes>
						<Route path={SIGNIN_PATH} element={<SignIn />} />
						<Route path={EMAIL_VERIFY_PATH} element={<EmailVerify />} />
						<Route path={PASSWORD_RESET} element={<PasswordReset />} />
						<Route path={PASSWORD_RECOVERY} element={<PasswordRecovery />} />
						<Route path={LOGIN_PATH} element={<Login />} />
						{<Route element={<ProtectedAuthRoute />}>
							<Route path="*" element={<MainLayout />} />
						</Route>}
				</Routes>
			</Suspense>
		</div>
	);
}

export default App;
