import { IInput } from './input.types';
import styles from './input.module.scss';
import classNames from 'classnames';

const Input = ({
	value,
	onChange,
	disabled = false,
	placeholder,
	error,
	type = 'text',
	className,
	...props
}: IInput) => {
	const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
		onChange && onChange(e);
	};
	return (
		<div className={classNames(styles.input_wrapper, className)}>
			<input
				placeholder={placeholder}
				className={styles.input_field}
				disabled={disabled}
				value={value}
				onChange={(e) => handleChange(e)}
				type={type}
				{...props}
			/>
			{!!error && <p className={styles.input_error}>{error}</p>}
		</div>
	);
};

export default Input;
