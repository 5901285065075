import ModalWrapper from '../modal-wrapper/modal-wrapper';
import styles from './auth-modal.module.scss';
import { IAuthModal } from './auth-modal.types';

const AuthModal = ({children}:IAuthModal) => {
	return (
		<ModalWrapper className={styles.auth_modal}>
			<div className={styles.auth_wrapper}>
				<h1 className={styles.auth_title}>notishop</h1>
        {children}
      </div>
		</ModalWrapper>
	);
};

export default AuthModal;
