import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { SIGN_IN_VALIDATION_ERRORS } from '../../../core/config/constants/initialValues/sign-in-form';
import { LOGIN_PATH } from '../../../core/config/routes';
import { getCookie } from '../../../core/utils/cookieUtils';
import { emailValidation } from '../../../core/utils/validation';
import { usePasswordReset, useSignin } from '../../../framework/react-query/auth-hooks';
import AuthModal from '../../common/auth-modal/auth-modal';
import Button from '../../common/button/button';
import Input from '../../common/input/input';
import styles from './sign-in.module.scss';

const SignIn = () => {
	const navigate = useNavigate();
	const [validationErrors, setValidationErrors] = useState(SIGN_IN_VALIDATION_ERRORS);
	const [username, setUsername] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfrimPass] = useState('');
	const { mutate, isSuccess, error } = useSignin();
	const { mutate: resetPassword } = usePasswordReset();

	const [emailError, setEmailError] = useState(false);

	const resetValidation = () => {
		setValidationErrors(SIGN_IN_VALIDATION_ERRORS);
	};

	useEffect(() => {
		if (error && error.response && error.response.status === 400) {
			resetPassword({ email });
			setEmailError(true);
		}
	}, [error]);

	const signIn = () => {
		resetValidation();
		if (!username) {
			setValidationErrors((prev) => ({ ...prev, username: 'Введите имя пользователя' }));
			return;
		}
		if (!email) {
			setValidationErrors((prev) => ({ ...prev, email: 'Введите email' }));
			return;
		}
		if (!emailValidation(email)) {
			setValidationErrors((prev) => ({ ...prev, email: 'Некорректный email' }));
			return;
		}
		if (!password || !confirmPassword) {
			setValidationErrors((prev) => ({ ...prev, password: 'Введите пароль' }));
			return;
		}
		if (password !== confirmPassword) {
			setValidationErrors((prev) => ({ ...prev, password: 'Пароли не совпадают' }));
			return;
		}
		const data = { username: username, email: email, password: password };
		mutate(data);
	};

	const refresh = getCookie('notishop_refresh');
	const access = localStorage.getItem('notishop_access');

	useEffect(() => {
		if (access || refresh) {
			navigate(LOGIN_PATH);
		}
	}, [access, refresh]);
	
	return (
		<div className={'main_wrapper'}>
			{!isSuccess && !emailError && (
				<AuthModal>
					<p className={styles.signin_subtitle}>
						Начните с бесплатного 14-ти дневного пробного периода
					</p>
					<div className={styles.signin_form}>
						<Input
							placeholder="Ваше имя"
							value={username}
							onChange={(e) => {
								resetValidation();
								setUsername(e.currentTarget.value);
							}}
							error={validationErrors.username}
						/>
						<Input
							placeholder="Электронная почта"
							value={email}
							onChange={(e) => {
								resetValidation();
								setEmail(e.currentTarget.value);
							}}
							error={validationErrors.email}
						/>
						<Input
							placeholder="Пароль"
							value={password}
							onChange={(e) => {
								resetValidation();
								setPassword(e.currentTarget.value);
							}}
							error={validationErrors.password}
							type="password"
						/>
						<Input
							placeholder="Повторите пароль"
							value={confirmPassword}
							onChange={(e) => setConfrimPass(e.currentTarget.value)}
							error={validationErrors.password}
							type="password"
						/>
					</div>
					<Button className={styles.signin_button} onClick={() => signIn()}>
						Зарегистрироваться
					</Button>
					<a
						href="https://docs.google.com/document/d/1DjZ-WaD9WI4RfqPD7jyjCeSOQi0K9yBcJ9S0V4YrABM/edit"
						className={styles.signin_link_conf}
					>
						Пользовательское соглашение
					</a>
					<Link to={LOGIN_PATH} className={styles.signin_link_login}>
						Уже зарегистрированы? Войти тут
					</Link>
				</AuthModal>
			)}
			{isSuccess && !emailError && (
				<AuthModal>
					<p className={styles.confirm_text}>
						Письмо для подтверждения отправлено на адрес <strong>{email}</strong>.
					</p>
					<p className={styles.confirm_text}>
						Пожалуйста, перейдите по ссылке, <br />
						указанной в этом письме. Если вы не видите письма, проверьте папку со спамом. Спасибо.
					</p>
					<Link className={styles.confirm_link} to={LOGIN_PATH}>
						Подтвердил почту. Хочу войти
					</Link>
				</AuthModal>
			)}
			{emailError && (
				<AuthModal>
					<div>
						<p className={styles['email-error-text']}>
							Ваш адрес уже был зарегистрирован в нашем сервисе.
						</p>
						<p className={styles['email-error-text']}>
							Мы отправили ссылку для сброса пароля на почту {email}.
						</p>
					</div>
					<Link to={LOGIN_PATH} className={styles.signin_link_conf}>
						Попробую вспомнить пароль и войти
					</Link>
				</AuthModal>
			)}
		</div>
	);
};

export default SignIn;
