import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/varables.scss';
import './index.scss';
import { store, persistor } from './infrastructure/store-layer/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider, Spin } from 'antd';
import { COLORS } from './core/config/constants/style/colors';
import { QueryClient, QueryClientProvider } from 'react-query';

import 'dayjs/locale/ru';
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: 1,
			staleTime: 5 * 1000,
		},
	},
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	// <React.StrictMode>
	<Provider store={store}>
		<PersistGate loading={<Spin />} persistor={persistor}>
			<QueryClientProvider client={queryClient}>
				<ConfigProvider
					theme={{
						token: {
							colorPrimary: COLORS.orange,
						},
					}}
				>
					<BrowserRouter>
						<App />
					</BrowserRouter>
				</ConfigProvider>
			</QueryClientProvider>
		</PersistGate>
	</Provider>
	// </React.StrictMode>
);
